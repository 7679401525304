@media (max-width: $breakpoint-lg) {
  .section-title p {
    max-width: none !important;
    width: auto !important;
    margin: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (max-width: $breakpoint-xl) {
  .why_choose_area {
    margin: 0;
    padding: 0;
  }
  .feature_bg {
    padding: 30px;
  }
}