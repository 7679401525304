section.skills_area {
  .skill_bg {
    @media (max-width: $breakpoint-lg) {
      padding: 50px 40px 40px !important;
    }
  }
}
.hero-slider {
  .slick-dots {
    bottom: 10px !important;
    button::before {
      color: #fff !important;
    }
  }
}