.single_project_area {
    img {
      width: 100%;
      aspect-ratio: 3/2;
      object-fit: cover;
    }

  .about_project {
    h4 {
      font-size: 2rem;
    }
  }
}
.project_details {
  h4 {
    border-color: $secondary;
  }
}

.interior-slick {
  .slick-arrow {
    z-index: 2;

    &.slick-prev  { left: 0 !important; }
    &.slick-next  { right: 0 !important; }
  }
  ul.slick-dots {
    li button::before {
      color: $secondary;
    }
  }
}