.footer {
  background: $primary;
  @media (max-width: $breakpoint-md) {
    padding-bottom: 15px !important;

    .footer_copyright {
      padding-top: 15px;
    }
  }

  .single_footer {
    padding: 0 !important;
  }
}

#contact {
  form {
    .form-group {
      position: relative;

      * {
        transition: all .2s ease-in-out;
      }

      label {
        color: #fff;
      }

      input, textarea {
        padding: 10px 10px 10px 30px !important;
      }

      :is(.is-invalid) {
       ~ .invalid-feedback {
          border: 1px solid $alert;
          padding: 2px 5px;
          background: #fff;
          position: relative;
          top: -28px;
        }
      }
      :is(.is-valid) {
       ~ .invalid-feedback {
          height: 0;
          display: none;
          opacity: 0;
          overflow: hidden;
        }
      }
    }
  }
}

.footer_logo {
  p,a {
    max-width: 240px;
    color: #ff9d10 !important;
  }
}

.footer_copyright {
  a.text-white:hover {
    color: orange !important;
  }
}

.footer {
  a.active {
    color: orange !important;
  }
}

.footer a {
  &::before {
    content: none !important;
  }
}