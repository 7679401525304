section.portfolio_area {
  .grid {
    grid-template-columns: repeat(3,1fr);
    grid-gap: 15px;

    img {
      object-fit: cover;
      width: 100%;
      height: 500px;
    }

    @media (max-width: $breakpoint-xl) {
      grid-template-columns: repeat(2, 1fr);
      img {
        height: 250px;
      }
    }
    @media (max-width: $breakpoint-md) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(3, 250px);
      overflow: hidden;
      img {
        height: 250px;
      }
    }
  }
}