::selection { background: $primary; color: #fff; }
::-moz-selection { background: $primary; color: #fff; }
html::-webkit-scrollbar { width: 10px; }
html::-webkit-scrollbar-track { background-color: transparent; }
html::-webkit-scrollbar-thumb { background: $primary; }
ul { padding: 0; }

* {
  box-sizing: border-box;
}

html,body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

i {
  font-size: 1.5rem !important;
  &.fa-xl {
    font-size: 2rem !important;
  }
}

a.no-dec {
  color: inherit !important;
  text-decoration: none !important;
}

.slick-arrow {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin: 0 1rem;

  &::before {
    display: flex; align-items: center; justify-content: center;
    height: 2rem; width: 2rem;
    font-family: "Font Awesome 6 Pro";
    font-size: 1rem;
    color: #fff;
    background: $primary;
    border-radius: 50%;
  }

  &.slick-next::before { content: "\f061"; }
  &.slick-prev::before { content: "\f060"; }
}