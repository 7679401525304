

.site-navbar {
margin-bottom: 0px;
z-index: 1999;
position: absolute;
top: 0;
width: 100%; }
.site-navbar .site-logo {
position: relative;
left: 0;
font-size: 24px; }
.site-navbar .site-navigation .site-menu {
margin-bottom: 0;float: right; }
.site-navbar .site-navigation .site-menu .active {
color: $secondary;
display: inline-block;
padding: 20px 20px; }
.site-navbar .site-navigation .site-menu a {
text-decoration: none !important;
display: inline-block; }
.site-navbar .site-navigation .site-menu > li {
display: inline-block; }
.site-navbar .site-navigation .site-menu > li > a {
	padding: 25px 20px;
	font-size: 16px;
	display: inline-block;
	text-decoration: none !important;
}
.site-navbar .site-navigation .site-menu > li > a:hover {
color: $secondary; }
.site-navbar .site-navigation .site-menu > li.social > a {
padding-left: 5px;
padding-right: 5px; }
.site-navbar .site-navigation .site-menu .has-children {
position: relative; }
.site-navbar .site-navigation .site-menu .has-children > a {
position: relative;
padding-right: 20px; }
.site-navbar .site-navigation .site-menu .has-children > a:before {
position: absolute;
content: "\e313";
font-size: 16px;
top: 50%;
right: 0;
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%);
font-family: 'icomoon'; }
.site-navbar .site-navigation .site-menu .has-children .dropdown {
visibility: hidden;
opacity: 0;
top: 100%;
position: absolute;
text-align: left;
border-top: 2px solid $secondary;
-webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
padding: 0px 0;
margin-top: 20px;
margin-left: 0px;
background: #fff;
-webkit-transition: 0.2s 0s;
-o-transition: 0.2s 0s;
transition: 0.2s 0s; }
.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
position: absolute; }
.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
bottom: 100%;
left: 20%;
border: solid transparent;
content: " ";
height: 0;
width: 0;
position: absolute;
pointer-events: none; }
.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
border-color: rgba(136, 183, 213, 0);
border-bottom-color: #fff;
border-width: 10px;
margin-left: -10px; }
.site-navbar .site-navigation .site-menu .has-children .dropdown a {
text-transform: none;
letter-spacing: normal;
-webkit-transition: 0s all;
-o-transition: 0s all;
transition: 0s all;
color: #000; }
.site-navbar .site-navigation .site-menu .has-children .dropdown .active {
color: $secondary !important; }
.site-navbar .site-navigation .site-menu .has-children .dropdown > li {
list-style: none;
padding: 0;
margin: 0;
min-width: 210px; }
.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
padding: 9px 20px;
display: block; }
.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a:hover {
background: #eff1f3;
color: #000; }
.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > a:before {
content: "\e315";
right: 20px; }
.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > .dropdown, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > ul {
left: 100%;
top: 0; }
.site-navbar .site-navigation .site-menu .has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children:focus > a, .site-navbar .site-navigation .site-menu .has-children:active > a {
color: $secondary; }
.site-navbar .site-navigation .site-menu .has-children:hover, .site-navbar .site-navigation .site-menu .has-children:focus, .site-navbar .site-navigation .site-menu .has-children:active {
cursor: pointer; }
.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown, .site-navbar .site-navigation .site-menu .has-children:focus > .dropdown, .site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
-webkit-transition-delay: 0s;
-o-transition-delay: 0s;
transition-delay: 0s;
margin-top: 0px;
visibility: visible;
opacity: 1; }

.site-mobile-menu {
width: 300px;
position: fixed;
right: 0;
z-index: 2000;
padding-top: 20px;
background: #fff;
height: calc(100vh);
-webkit-transform: translateX(110%);
-ms-transform: translateX(110%);
transform: translateX(110%);
-webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
-webkit-transition: .3s all ease-in-out;
-o-transition: .3s all ease-in-out;
transition: .3s all ease-in-out; }
.offcanvas-menu .site-mobile-menu {
-webkit-transform: translateX(0%);
-ms-transform: translateX(0%);
transform: translateX(0%); }
.site-mobile-menu .site-mobile-menu-header {
width: 100%;
float: left;
padding-left: 20px;
padding-right: 20px; }
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
float: right;
margin-top: 8px; }
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
font-size: 30px;
display: inline-block;
padding-left: 10px;
padding-right: 0px;
line-height: 1;
cursor: pointer;
-webkit-transition: .3s all ease;
-o-transition: .3s all ease;
transition: .3s all ease; }
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
float: left;
margin-top: 10px;
margin-left: 0px; }
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
display: inline-block;
text-transform: uppercase; }
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
max-width: 70px; }
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
text-decoration: none; }
.site-mobile-menu .site-mobile-menu-body {
overflow-y: scroll;
-webkit-overflow-scrolling: touch;
position: relative;
padding: 0 20px 20px 20px;
height: calc(100vh - 52px);
padding-bottom: 150px; }
.site-mobile-menu .site-nav-wrap {
padding: 0;
margin: 0;
list-style: none;
position: relative; }
.site-mobile-menu .site-nav-wrap a {
padding: 10px 20px;
display: block;
position: relative;
color: #212529; }
.site-mobile-menu .site-nav-wrap a:hover {
color: $secondary; }
.site-mobile-menu .site-nav-wrap li {
position: relative;
display: block; }
.site-mobile-menu .site-nav-wrap li .active {
color: $secondary; }
.site-mobile-menu .site-nav-wrap .social {
display: inline-block; }
.site-mobile-menu .site-nav-wrap .arrow-collapse {
position: absolute;
right: 0px;
top: 10px;
z-index: 20;
width: 36px;
height: 36px;
text-align: center;
cursor: pointer;
border-radius: 50%; }
.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
background: #f8f9fa; }
.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
font-size: 12px;
z-index: 20;
font-family: "icomoon";
content: "\f078";
position: absolute;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%, -50%) rotate(-180deg);
-ms-transform: translate(-50%, -50%) rotate(-180deg);
transform: translate(-50%, -50%) rotate(-180deg);
-webkit-transition: .3s all ease;
-o-transition: .3s all ease;
transition: .3s all ease; }
.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
-webkit-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%); }
.site-mobile-menu .site-nav-wrap > li {
display: block;
position: relative;
float: left;
width: 100%; }
.site-mobile-menu .site-nav-wrap > li.social {
float: none !important;
width: auto !important; }
.site-mobile-menu .site-nav-wrap > li > a {
padding-left: 20px;
font-size: 20px; }
.site-mobile-menu .site-nav-wrap > li > ul {
padding: 0;
margin: 0;
list-style: none; }
.site-mobile-menu .site-nav-wrap > li > ul > li {
display: block; }
.site-mobile-menu .site-nav-wrap > li > ul > li > a {
padding-left: 40px;
font-size: 16px; }
.site-mobile-menu .site-nav-wrap > li > ul > li > ul {
padding: 0;
margin: 0; }
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
display: block; }
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
font-size: 16px;
padding-left: 60px; }
.site-mobile-menu .site-nav-wrap[data-class="social"] {
float: left;
width: 100%;
margin-top: 30px;
padding-bottom: 5em; }
.site-mobile-menu .site-nav-wrap[data-class="social"] > li {
width: auto; }
.site-mobile-menu .site-nav-wrap[data-class="social"] > li:first-child a {
padding-left: 15px !important; }

.sticky-wrapper {
position: absolute;
z-index: 100;
width: 100%; }
.sticky-wrapper .site-navbar {
-webkit-transition: .3s all ease;
-o-transition: .3s all ease;
transition: .3s all ease; }
.sticky-wrapper .site-navbar {
border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
.sticky-wrapper .site-navbar .site-menu-toggle {
color: #fff; }
.site-logo img{width:250px;}
.sticky-wrapper .site-navbar .site-logo a {
color: #fff; }
.sticky-wrapper .site-navbar .site-menu > li > a {
color: #fff; }
.sticky-wrapper .site-navbar .site-menu > li > a:hover, .sticky-wrapper .site-navbar .site-menu > li > a.active {
color: #fff; }
.sticky-wrapper.is-sticky .site-navbar {
background: #fff;
border-bottom: 1px solid transparent;
-webkit-box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.3);
box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.3); }
.sticky-wrapper.is-sticky .site-navbar .site-menu-toggle {
color: #000; }
.sticky-wrapper.is-sticky .site-navbar .site-logo a {
color: $secondary; }

.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a:hover, .sticky-wrapper.is-sticky .site-navbar .site-menu > li > a.active {
color: $secondary !important; }
