.sticky-wrapper.is-sticky .site-navbar {
  background: $primary;
  padding: 10px 0;
  color: #fff;

  * {
    color: #fff !important;
  }
}

.site-navbar {
  border: none !important;
}

.sticky-wrapper:not(:is(.is-sticky)) {
  .menu-btn i:last-of-type {
    color: #000 !important;
  }
}
ul.site-menu {
  li {
    a {
      text-transform: lowercase !important;
      transition: all .2s ease-in-out;
    }
    &:hover {
      a { color: $secondary !important; }
    }
  }
}

//Mobile menu
.site-mobile-menu.site-navbar-target {
  transition: all .2s ease-in-out;
  z-index: 100 !important;
  width: clamp(300px, 50%, 100vw);

  .site-mobile-menu-body {
    padding: 50px 20px 20px 20px;
  }

  &.open {
    transform: translateX(0);
  }
}

.menu-btn {
  display: none;
  @media (max-width: $breakpoint-xl) {
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 100;
    position: relative;
    i:first-of-type {
      opacity: 1;
    }
    i:last-of-type {
      opacity: 0
    }

    &.open {
      i:first-of-type {
        opacity: 0;
      }

      i:last-of-type {
        opacity: 1;
      }
    }

    //standard I styling
    i {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10000;
      color: #fff;
    }
  }
}

a.active {
  color: orange !important;
}

.site-logo img {
  @media (max-width: $breakpoint-xl) {
    padding-top: 10px;
  }
}