.slider {
  img {
    width: 100%;
    padding: 0 20px;
    aspect-ratio: 2/1;
    object-fit: contain;
  }
  .slick-arrow::before  {
    color: #000;
  }
}