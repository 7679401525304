#toggler {
  position: fixed; right: 0; top:50%;
  display: flex;
  transform: translateY(-50%) translateX( calc(100% - 60px) );
  transition: all .2s ease-in-out;
  z-index: 40;
  cursor: pointer !important;
  * {
    cursor: pointer !important
  }

 &.show {
    transform: translateY(-50%) translateX( 0 ) !important;
  }
  &:hover {
    transform: translateY(-50%) translateX( calc(100% - 120px) );
  }
  .content {
    padding: 30px; padding-left: 80px !important;
    border-bottom-left-radius: 999px; border-top-left-radius: 999px;
    background: #fff;
    overflow: hidden !important;
    position: relative;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    cursor: pointer;

    &::before {
      width: 60px;
      height: 100%;
      background: $secondary;
      position: absolute; left: 0; top: 50%; transform: translateY(-50%);
      display: flex; align-items: center; justify-content: center;
      content: "\f133"; font-family: "Font Awesome 6 Pro"; color: #fff; font-size: 1.6rem;
    }
  }
}