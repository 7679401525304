.primary                        { color: $primary }
.secondary                      { color: $secondary }
.gray,.grey                     { color: $lightgrey }

.block                          { display: block }
.inline-block                   { display: inline-block }
.flex                           { display: flex }
.grid                           { display: grid }

.underline                      { text-decoration: underline; }
.no-underline                   { text-decoration: none !important; }

.fa-xl                          { font-size: 1.5rem !important; }

.border-radius                  { border-radius: $border-radius }


// ---------------------------------------
// \
// \ MEDIA-QUERY'S W/ MAX-WIDTH
// \
// ----------------------------------------
@media (max-width: $breakpoint-xxl) {

  .overflow-xxl-hidden         { overflow: hidden }

}
@media (max-width: $breakpoint-xl) {

  .overflow-xl-hidden         { overflow: hidden }

}
@media (max-width: $breakpoint-lg) {

  .overflow-lg-hidden         { overflow: hidden }

}
@media (max-width: $breakpoint-md) {

  .overflow-md-hidden         { overflow: hidden }

}
@media (max-width: $breakpoint-sm) {

  .overflow-sm-hidden         { overflow: hidden }

}

// ---------------------------------------
// \
// \ MEDIA-QUERY'S W/ MIN-WIDTH + 1px
// \
// ----------------------------------------
@media (min-width: calc($breakpoint-xxl + 1px)) {

  .vh-xxl-100                 { height: 100vh }

}
@media (min-width: calc($breakpoint-xl + 1px)) {

  .vh-xl-100                 { height: 100vh }

}
@media (min-width: calc($breakpoint-lg + 1px)) {

  .vh-lg-100                 { height: 100vh }

}
@media (min-width: calc($breakpoint-md + 1px)) {

  .vh-md-100                 { height: 100vh }

}
@media (min-width: calc($breakpoint-sm + 1px)) {

  .vh-sm-100                 { height: 100vh }

}


