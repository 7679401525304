.image-overview_slider {
  .slick-arrow {
    z-index: 2;

    &.slick-prev  { left: 30px !important; }
    &.slick-next  { right: 30px !important; }
  }
  ul.slick-dots {
    li button::before {
      color: $secondary;
    }
  }
  .slick-slide {
    margin: 0 10px;
  }
}