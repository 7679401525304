.xxl-rm {
  @media (max-width: 2000px) {
    display: none;
  }
}
.xl-rm {
  @media (max-width: 1400px) {
    display: none;
  }
}
.lg-rm {
  @media (max-width: 992px) {
    display: none;
  }
}
.md-rm {
  @media (max-width: 768px) {
    display: none;
  }
}
.sm-rm {
  @media (max-width: 576px) {
    display: none;
  }
}