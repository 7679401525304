@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');

.font-classic {
  font-family: 'PT Serif', serif !important;
}

@media (max-width: $breakpoint-xl) {
  h2 {
    font-size: 40px !important;
  }
}

p.light {
  color: $lightgrey;
}
// -#-#-#-#-#- END STANDARD TYPOGRAPHY -#-#-#-#-#- //